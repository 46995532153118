import React, { Suspense } from "react";
const PortfolioWrapper = React.lazy(() =>
  import("../components/PortfolioWrapper")
);

const PortfolioPage = () => {
  return (
    <Suspense fallback={<></>}>
      <PortfolioWrapper />
    </Suspense>
  );
};

export default PortfolioPage;
