import React, { Suspense } from "react";
const ExploreWrapper = React.lazy(() => import("../components/ExploreWrapper"));

const ExplorePage = () => {
  return (
    <Suspense fallback={<></>}>
      <ExploreWrapper />
    </Suspense>
  );
};

export default ExplorePage;
