import React, { Suspense } from "react";
const ProductWrapper = React.lazy(() => import("../components/ProductWrapper"));

const ProductPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ProductWrapper />
    </Suspense>
  );
};

export default ProductPage;
