import React, { Suspense } from "react";
const PortfolioEditWrapper = React.lazy(() =>
  import("../components/PortfolioEditWrapper")
);

const PortfolioEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <PortfolioEditWrapper />
    </Suspense>
  );
};

export default PortfolioEditPage;
