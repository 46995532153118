import React, { Suspense } from "react";
const ExploreEditWrapper = React.lazy(() =>
  import("../components/ExploreEditWrapper")
);

const ExploreEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ExploreEditWrapper />
    </Suspense>
  );
};

export default ExploreEditPage;
